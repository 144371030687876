import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "aktPSPD";
const modelPath = `/aktivitas/`;

class AktivitasPSPD extends ModelBase {
  constructor() {
    let model = {
        id: null,
        perawatan: null,
        preceptor: null,
        lokasi: null,
        tanggal: null,
        kompetensi: null,
        catatan: ""
    };
    let requiredFields = ["preceptor", "lokasi", "tanggal", "kompetensi"];
    super(modelName, model, requiredFields, ["perawatan", "catatan"], modelPath);
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    if (tdata.perawatan) tdata.perawatan = tdata.perawatan.id;
    tdata.preceptor = tdata.preceptor.id;
    tdata.lokasi = tdata.lokasi.id;
    tdata.kompetensi = tdata.kompetensi.id;
    return tdata;
  }
}

export default AktivitasPSPD;